<template>
  <div>
    <b-tabs>
      <b-tab
        :title="`Datos del ${$getVisibleNames(
          'mesh.cycle',
          false,
          'Ciclo Formativo'
        )}`"
      >
        <h4 v-if="isNaN(cycle.id) && show_title">
          Crear {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
        </h4>
        <h4 v-else-if="show_title">
          Editar {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
        </h4>
        <div>
          <b-form-group
            label="Nombre"
            label-for="name-input"
            label-cols="0"
            label-cols-sm="3"
            label-class="ml-1"
            class="p-0 mt-3"
          >
            <b-form-input
              id="name-input"
              name="name-input"
              v-model="$v.cycle.name.$model"
              :state="validateState('name')"
              aria-describedby="input-name-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-name-live-feedback"
              >Este campo es obligatorio y debe tener al menos 3
              caracteres.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            label="Descripción"
            label-for="description-input"
            label-cols="0"
            label-cols-sm="3"
            label-class="ml-1"
            class="p-0"
          >
            <b-form-textarea
              id="description-input"
              name="description-input"
              v-model="$v.cycle.description.$model"
              :state="validateState('description')"
              aria-describedby="input-description-live-feedback"
            ></b-form-textarea>

            <b-form-invalid-feedback id="input-description-live-feedback"
              >Este campo es obligatorio y debe tener al menos 3
              caracteres.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            label="Nivel de Inicio"
            label-for="init-semester-input"
            label-cols="0"
            label-cols-sm="3"
            label-class="ml-1"
            class="p-0"
          >
            <b-form-input
              id="init-semester-input"
              name="init-semester-input"
              v-model="$v.cycle.init_semester.$model"
              @change="forceRerender"
              type="number"
              :state="validateState('init_semester')"
              aria-describedby="input-init-semester-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-init-semester-live-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            label="Nivel de Término"
            label-for="end-semester-input"
            label-cols="0"
            label-cols-sm="3"
            label-class="ml-1"
          >
            <b-form-input
              id="end-semester-input"
              name="end-semester-input"
              v-model="$v.cycle.end_semester.$model"
              type="number"
              @change="forceRerender"
              :state="validateState('end_semester')"
              aria-describedby="input-end-semester-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-end-semester-live-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            label="Certificación"
            label-for="certification-input"
            label-cols="0"
            label-cols-sm="3"
            label-class="ml-1"
          >
            <b-form-textarea
              id="certification-input"
              name="certification-input"
              v-model="$v.cycle.certification.$model"
              :state="validateState('certification')"
              aria-describedby="input-certification-live-feedback"
            ></b-form-textarea>

            <b-form-invalid-feedback id="input-certification-live-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </b-tab>
      <b-tab
        :title="`Asignar ${$getVisibleNames(
          'mesh.egressprofile',
          false,
          'Perfil de Egreso'
        )}`"
      >
        <EgressProfileCycle
          :key="componentKey"
          :Cycle="cycle"
          :init_semester="cycle.init_semester"
          :end_semester="cycle.end_semester"
          @updated="slotUpdatedProfileCycle"
        ></EgressProfileCycle>
      </b-tab>
      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(cycle.id)"
          class="col"
          style="text-align: left"
        >
          <b-button class="mr-1" size="sm" variant="danger" @click="deleteCycle"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength, numeric } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";

export default {
  name: "CycleForm",
  components: {
    EgressProfileCycle: () =>
      import(
        "@/components/mesh/EgressProfile/EgressProfileCycle/EgressProfileCycle"
      ),
  },
  mixins: [validationMixin],
  props: {
    Cycle: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
    cycle_egress_profiles: {
      type: Array,
    },
  },
  data() {
    return {
      componentKey: 0,
      egress_profile_cycles: [],
      cycle: this.Cycle
        ? {
            id: this.Cycle.id,
            name: this.Cycle.name,
            description: this.Cycle.description,
            init_semester: this.Cycle.init_semester,
            end_semester: this.Cycle.end_semester,
            certification: this.Cycle.certification,
          }
        : {
            id: generateUniqueId(),
            name: "",
            description: "",
            init_semester: 1,
            end_semester: 1,
            certification: "",
          },
    };
  },
  validations: {
    cycle: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      init_semester: {
        numeric,
      },
      end_semester: {
        numeric,
      },
      certification: {},
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.cycle[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.cycle.$touch();
      if (this.$v.cycle.$anyError) {
        return;
      }
      if (isNaN(this.cycle.id)) this.createCycle();
      else this.updateCycle();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    createCycle() {
      this.$store.dispatch(names.POST_CYCLE, this.cycle).then((response) => {
        this.cycle.id = response.id;
        toast(
          this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
            " creado."
        );
        this.saveProfileCycles();
        this.$emit("created", response);
      });
    },
    updateCycle() {
      this.$store.dispatch(names.UPDATE_CYCLE, this.cycle).then((response) => {
        this.cycle.id = response.id;
        toast(
          this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
            " actualizado."
        );
        this.saveProfileCycles();
        this.$emit("updated", this.cycle);
      });
    },
    deleteCycle() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.cycle",
          false,
          "Ciclo Formativo"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CYCLE, this.cycle.id);
          this.$emit("deleted", this.cycle);
        }
      });
    },
    slotUpdatedProfileCycle(egress_profile_cycles) {
      this.egress_profile_cycles = egress_profile_cycles;
    },
    saveProfileCycles() {
      this.egress_profile_cycles.forEach((egress_profile_cycle) => {
        if (egress_profile_cycle.selected) {
          if (!egress_profile_cycle.id || isNaN(egress_profile_cycle.id)) {
            this.createProfileCycle(egress_profile_cycle);
          } else {
            this.updateProfileCycle(egress_profile_cycle);
            // TODO: Garantizar de alguna forma que el objeto no se actualice si no ha sido moficado.
          }
        } else {
          if (egress_profile_cycle.id && !isNaN(egress_profile_cycle.id)) {
            this.deleteProfileCycle(egress_profile_cycle);
          }
        }
      });
    },
    createProfileCycle(profile_cycle) {
      const payload = {
        egress_profile: profile_cycle.egress_profile,
        init_semester: profile_cycle.init_semester,
        end_semester: profile_cycle.end_semester,
        cycle: this.cycle.id,
      };
      this.$store
        .dispatch(names.POST_PROFILE_CYCLE, payload)
        .then((response) => {
          toast(
            String(
              "Relación entre " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                " y " +
                this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " creada."
            )
          );
          this.$emit("created_egress_profile_cycle", response);
          this.$emit("close", this.cycle.id);
        });
    },
    updateProfileCycle(profile_cycle) {
      this.$store
        .dispatch(names.UPDATE_PROFILE_CYCLE, profile_cycle)
        .then(() => {
          toast(
            String(
              "Relación entre " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                " y " +
                this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " actualizada."
            )
          );
          this.$emit("updated_egress_profile_competence", profile_cycle);
          this.$emit("close", this.cycle.id);
        });
    },
    deleteProfileCycle(egress_profile_cycle) {
      if (isNaN(egress_profile_cycle.id) || !egress_profile_cycle.id) return;
      this.$store
        .dispatch(names.DELETE_PROFILE_CYCLE, egress_profile_cycle.id)
        .then(() => {
          toast(
            String(
              this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " eliminado del " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                "."
            )
          );
          this.$emit("close", this.cycle.id);
        });
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  created() {},
  mounted() {},
};
</script>

<style>
</style>